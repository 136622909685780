<template>
  <div id="app">
    <div id="nav">Lütfen Barkodu okutunuz..</div>
    <StreamBarcodeReader
      @decode="onDecode"
      @loaded="onLoaded"
    ></StreamBarcodeReader>

    <span>Sonuç:</span> <p>{{ result }}</p>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  name: "Camera",
  data(){
    return {
      result:''
    }
  },
  components: {
    StreamBarcodeReader,
  },
  methods: {
    onDecode(result) {
      this.result = result
    },
    onLoaded(result) {
      console.log(result);
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
